import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form } from "react-bootstrap"
import {formContainer, formGroup } from "./contactus.module.css"

const ServiceRequest = ({ data, services }) => {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <div className={formContainer + " page-container"}>
        <h2 className="page-title">Service Request</h2>
        <Form>
          <Form.Group className={formGroup} controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="John Doe" />
          </Form.Group>
          <Form.Group className={formGroup} controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="John@example.com" />
          </Form.Group>
          <Form.Group className={formGroup} controlId="formPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="phone" placeholder="(123) 123-4567" />
          </Form.Group>
          <Form.Group className={formGroup} controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control type="text" placeholder="123 Main St ..." />
          </Form.Group>
          <Form.Group className={formGroup} controlId="formReason">
            <Form.Label>Reason</Form.Label>
            <Form.Control as="select">
            <option>Select a reason...</option>
            <option>Whole Home Surge Protection</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className={formGroup} controlId="formDetails">
            <Form.Label>Details</Form.Label>
            <Form.Control as="textarea" rows="5" />
          </Form.Group>
        </Form>
      </div>
    </Layout>
  )
}

export default ServiceRequest
